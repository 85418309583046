.navbar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.navbar a {
  color: black;
}

.menu-bars {
  padding-top: 2rem;
  padding-left: 2rem;
  font-size: 3rem;
}

.nav-menu {
  background-color: black;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 850ms;
  background-color: white;
}

/* NAVBAR TEXT */
.nav-text {
  margin-left: 2rem;
  margin-top: 2rem;
  font-size: 1.25rem;
  list-style: none;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
}

.nav-text a {
  text-decoration: none;
}

.nav-text a:hover {
  text-decoration: underline;
}

/* SIDEBAR TEXT */
.sidebar-text {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  list-style: none;
  color: black;
}

.sidebar-text a {
  width: 100%;
  margin-top: 1rem;
  margin-left: 2rem;
  text-decoration: none;
  color: black;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
}

.sidebar-text a:hover {
  text-decoration: underline;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (min-width: 768px) {
  .sidebar-text a {
    font-size: 2rem;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
